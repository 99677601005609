import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/b7146c2/components/layout-content.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Theme`}</h1>
    <p>{`All design tokens and keys that are generated adhere to
the `}<a parentName="p" {...{
        "href": "https://system-ui.com/theme"
      }}>{`System UI Theme Specification`}</a>{`.
This ensures interoperability with React libraries and design
tools that support the spec.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/theme"
        }}><inlineCode parentName="a">{`/theme`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/api/generate/theme"
        }}><inlineCode parentName="a">{`/api/generate/theme`}</inlineCode></a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      